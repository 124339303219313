import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {Container } from "../styles/pagenotfound";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <Container>
      <h1>Page not found</h1>
    </Container>
  </Layout>
)

export default NotFoundPage
