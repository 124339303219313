import styled from "styled-components"

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  padding-top: 100px;

  h1 {
    text-align: center;
  }
`
